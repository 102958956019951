<template>
<div class="flex w-full flex-col">
    <div class="pt-16 px-2 w-full container md:m-auto flex">
        <div class="w-full flex flex-wrap">
            <div class="md:hidden px-2 w-full container flex flex-wrap">
                <!--<Search />-->
            </div>
            <router-link  v-for="(item,i) in items" :key="i"  :to='item.path' class="ml-5 text-white">
                <div :class="[{'hidden': !Can(item.role),'w-60 flex items-center justify-start hover:bg-blue p-2':Can(item.role)}]">
                    <div class="w-10 h-10 flex items-center justify-center bg-yellow rounded-full">
                        <img width="25px" height="25px" class="" :src="'/assets/'+item.image" :alt="item.name">
                    </div>
                    <div class="mx-6">
                        {{item.name}}
                    </div>
                </div>
            </router-link>
            <div class="w-60 flex items-center hover:bg-blue p-2">
                <FormTemplate @response="logoutResponse" id="logout-form" method="post" action="/auth/logout" button="Logout">
                </FormTemplate>
            </div>
        </div>
    </div>


</div>
</template>

<script>
import FormTemplate from '../Form'

export default {
    name: 'Menu',
    data: () => ({
        items: [
            {
                name: 'Dashboard',
                role: 'User',
                image: 'dashboard.png',
                path: '/',
            },
            {
                name: 'Services',
                role: 'Service',
                image: 'services.png',
                path: '/services',
            },
            {
                name: 'Products',
                role: 'Product',
                image: 'products.png',
                path: '/products',
            },
            {
                name: 'Downloads',
                role: 'Download',
                image: 'downloads.png',
                path: '/downloads',
            },
            {
                name: 'Coupons',
                role: 'Coupon',
                image: 'coupons.png',
                path: '/coupons',
            },
            {
                name: 'Customers',
                role: 'Customer',
                image: 'customers.png',
                path: '/customers',
            },
            {
                name: 'Affiliates',
                role: 'Affiliate',
                image: 'customers.png',
                path: '/affiliates',
            },
            {
                name: 'Agencies',
                role: 'Agency',
                image: 'agencies.png',
                path: '/agencies',
            },
            {
                name: 'Licenses',
                role: 'License',
                image: 'licenses.png',
                path: '/licenses',
            },
            {
                name: 'Domains',
                role: 'Domain',
                image: 'domains.png',
                path: '/domains',
            },
            {
                name: 'Roles',
                role: 'Role',
                image: 'roles.png',
                path: '/roles',
            },
            {
                name: 'Reports',
                role: 'Reports',
                image: 'reports.png',
                path: '/reports',
            },
            {
                name: 'Users',
                role: 'Users',
                image: 'users.png',
                path: '/users',
            },
            {
                name: 'User Logs',
                role: 'UserLog',
                image: 'userlogs.png',
                path: '/user-logs',
            },
            {
                name: 'Form Submissions',
                role: 'FormSubs',
                image: 'formsubmissions.png',
                path: '/form-submissions',
            },
            {
                name: 'Subscriptions',
                role: 'Subscriptions',
                image: 'subscriptions.png',
                path: '/subscriptions',
            },
            {
                name: 'Orders',
                role: 'Orders',
                image: 'orders.png',
                path: '/orders',
            },
            {
                name: 'Profile',
                role: 'User',
                image: 'profile.png',
                path: '/profile',
            },
            {
                name: 'Tags',
                role: 'Tags',
                image: 'tags.png',
                path: '/tags',
            },
            {
                name: 'Settings',
                role: 'Settings',
                image: 'settings.png',
                path: '/settings',
            },
            /*
            {
                name: 'Emails',
                role: 'Email',
                image: 'emails.png',
                path: '/emails',
            },
            */
            {
                name: 'Calls',
                role: 'Call',
                image: 'calls.png',
                path: '/calls',
            },
            {
                name: 'Success Criteria',
                role: 'SuccessCriteria',
                image: 'check-circle-filled.png',
                path: '/successcriteria',
            },
            {
                name: 'Payments',
                role: 'Payments',
                image: 'payment_profiles.png',
                path: '/payments',
            },
            {
                name: 'GlobalSettings',
                role: 'GlobalSettings',
                image: 'formsubmissions.png',
                path: '/globalsettings',
            },
            {
              name: 'PAP Members',
              role: 'Agency',
              image: 'users.png',
              path: '/pap',
            }
            // {
            //     name: 'Tickets',
            //     role: 'Tickets',
            //     image: 'tickets.png',
            //     path: '/tickets',
            // },
        ],
    }),
    computed: {
        User() {
            return this.$store.state.user.user
        },
        roles() {
            return this.$store.state.user.roles;
        },
    },
    props: [],
    methods: {
        KeyDown(e) {
            if(e.key === "Escape"){
                this.$store.commit('menu/setState',{key:'open',value: false})
            }
        },
        Can(role) {
            if(this.roles['Admin']) return true;
            if(this.roles[role]) return true;
            return false;
        },
        logoutResponse(re){
            if(re.data.status == 'OK'){
                this.$store.commit('user/setState',{key:'user',value: false})
                window.location.reload();
            }
        },
    },
    watch: {
    },
    created() {
        document.addEventListener('keydown', this.KeyDown,true)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.KeyDown, true)
    },
    mounted() {
    },
    components: {
        FormTemplate,
    },
}
</script>
<style scoped>
</style>
