<template>
<div id="dashboard">
    <DashboardHeader></DashboardHeader>
    <SubHeader />
    <main class="min-h-screen w-full flex z-50">
        <div class="container mx-auto flex">
            <router-view/>
        </div>
        <div id="app-menu" :class="[{'hidden': !menu_open},'h-full md:h-auto fixed left-0 right-0 bg-black text-white flex p-6 overflow-y-scroll z-40 top-0']">
            <Menu></Menu>
        </div>
    </main>
</div>
</template>

<script>
import DashboardHeader from '../../components/dashboard/Header'
import Menu from '../../components/dashboard/Menu'
import SubHeader from '../../components/dashboard/SubHeader'
export default {
    name: 'Dashboard',
    data: () => ({
    }),
    computed: {
        menu_open() {
            return this.$store.state.menu.open
        },
    },
    props: [],
    methods: {
    },
    watch: {
        $route() {
            this.$store.commit('menu/setState',{key:'open',value: false})
        },
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        DashboardHeader,
        Menu,
        SubHeader,
    },
}
</script>
<style scoped>
</style>
