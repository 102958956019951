<template>
<!-- This example requires Tailwind CSS v2.0+ -->
<nav class="h-16 z-50 fixed bg-black inset-x-0 top-0">
  <div class="w-full">
    <div class="flex-col relative flex items-center justify-between h-16 z-50">
      <div class="px-2 container flex items-center justify-start">
        <!-- Mobile menu button-->
        <button @click="ToggleMenu" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" :aria-expanded="menu_open">
          <span class="sr-only">Open main menu</span>
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div class="p-2 w-full container m-auto flex flex-wrap">
            <!-- <div v-if="Can('Orders')" class="input-wrapper p-2 flex flex-col relative">
                <ButtonTemplate title="Creat New Order" class="flex rounded-full items-center justify-center" :on_click="GoToOrder" btn_style="secondary">
                  + <img height="15px" width="15px" src="/assets/orders.png" alt="Create New Order">
                </ButtonTemplate>
            </div> -->
            <!--
            <div v-if="Can('Call')" class="input-wrapper p-2 flex flex-col relative">
                <ButtonTemplate title="Creat New Call" class="flex rounded-full items-center justify-center" :on_click="GoToCall" btn_style="secondary">
                  + <img height="15px" width="15px" src="/assets/calls.png" alt="Create New Call">
                </ButtonTemplate>
            </div>
            <div v-if="Can('Tickets')" class="input-wrapper p-2 flex flex-col relative">
                <ButtonTemplate title="Creat New Ticket" class="flex rounded-full items-center justify-center" :on_click="GoToTicket" btn_style="secondary">
                  + <img height="15px" width="15px" src="/assets/tickets.png" alt="Create New Ticket">
                </ButtonTemplate>
            </div>
            -->
        </div>
        <div class="md:block px-2 w-full container flex flex-wrap">
            <!--<Search />-->
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
      </div>
    </div>
  </div>
</nav>



</template>

<script>

export default {
    name: 'Header',
    data: () => ({
    }),
    computed: {
      menu_open() {
        return this.$store.state.menu.open
      },
      User() {
          return this.$store.state.user.user
      },
      roles() {
          return this.$store.state.user.roles;
      },
    },
    props: [],
    methods: {
      GoToOrder() {
        this.$router.push({path:'/orders/create'})
      },
      GoToTicket() {
        this.$router.push({path:'/tickets/create'})
      },
      GoToCall() {
        this.$router.push({path:'/calls/create'})
      },
      Can(role) {
          if(this.roles['Admin']) return true;
          if(this.roles[role]) return true;
          return false;
      },
      ToggleMenu() {
        this.$store.commit('menu/setState',{key:'open',value: !this.$store.state.menu.open})
      },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
