<template>
<div class="w-full h-10 text-white flex mt-16 bg-blue shadow-lg"> 
    <div class="flex items-center mx-auto container h-full">
        <div class="flex items-center">
            <button @click="$router.go(-1)" title="Click to Go Back" class="h-full" aria-label="Click to Go Back">
                <img class="mx-4 button-icon" src="/assets/chevron-left-white.png" />
            </button>
            <button @click="$router.go(1)" title="Click to Go Forward" class="h-full" aria-label="Click to Go Forward">
                <img class="mx-4 button-icon" src="/assets/chevron-right-white.png" />
            </button>
        </div>
        <div class="flex items-center">
            <button ref="History_Button" id="History_Button" @click="History" title="History" class="h-full" aria-label="History">
                <img class="mx-4 button-icon" src="/assets/history-white.png" />
            </button>
        </div>
        <div class="flex items-center">
            <span class="text-white cursor-pointer" @click="CopyRoutePath">
                {{$route.path}}
                <input class="sr-only" ref="hiddenClipboardCopy" value="" type="text">
            </span>
        </div>
        <Dropdown @closed="ShowHistory=false" trigger="History_Button" style="max-height:300px;overflow-y:scroll;" :show="ShowHistory">
            <router-link v-for="(path,i) in history" :key="i" :to="{path:path}" class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm " role="menuitem" tabindex="-1" :id="'menu-item-'+i">
                {{path}}
            </router-link>
        </Dropdown>
    </div>
</div>


</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: 'SubHeader',
    data: () => ({
        history: [],
        ShowHistory: false,
    }),
    computed: {
        currentRoute() {
            return this.$route
        },
    },
    props: [],
    methods: {
        GetHistory() {
            if(Cookies.get('oada_user_nav_history')) {
                this.history = Cookies.get('oada_user_nav_history')
                this.history = JSON.parse(this.history)
            }else{
                Cookies.set('oada_user_nav_history',JSON.stringify(this.history),{expires: 1})
            }
        },
        CopyRoutePath() {
            let copyText = this.$refs['hiddenClipboardCopy']
            copyText.value = window.location.href
            copyText.select()
            copyText.setSelectionRange(0, 99999)
            document.execCommand("copy");
            this.$notify({
                title: 'Url Copied to clipboard!',
                text: '',
                type: 'success',
            });
        },
        History() {
            this.ShowHistory = !this.ShowHistory
        },
    },
    watch: {
        $route(val){
            if(val.path){
                let index = this.history.indexOf(val.path)
                if(index > -1) this.history.splice(index,1)
                this.history.push(val.path)
                Cookies.set('oada_user_nav_history',JSON.stringify(this.history),{expires: 1})
            }
        },
    },
    created() {
        this.GetHistory()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
.button-icon {
    height: 25px;
}
</style>
