<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Customers
        </h1>
        <LinkTemplate :to="{path:'/customers/create'}" class="my-6">
            Add New Customer
        </LinkTemplate>
    </div>
    <div class="">
        <div class="flex">
            <b>Search by:</b> 
            <Checkbox label="ID" v-model="searchFields.id" :required="false" ></Checkbox>
            <Checkbox label="First Name" v-model="searchFields.fname" :required="false" ></Checkbox>
            <Checkbox label="Last Name" v-model="searchFields.lname" :required="false" ></Checkbox>
            <Checkbox label="Email" v-model="searchFields.email" :required="false" ></Checkbox>
            <Checkbox label="User Name" v-model="searchFields.uname" :required="false" ></Checkbox>
        </div>

        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != '' " class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/customers/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/customers/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'CustomerList',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/customers',
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'last_name',
                label:'Last Name',
            },
            {
                id:'first_name',
                label:'First Name',
            },
            {
                id:'email',
                label:'Email',
            },
            {
                id:'username',
                label:'Username',
            },
            {
                id:'agencies',
                label:'Agencies',
                html_custom: 'DisplayAgencies',
            },
        ],
        searchFields: {
            id: true,
            lname: true,
            fname: true,
            email: true,
            uname: true,
        },
    }),
    computed: {
        searchParams() {

            var fields = [];
            if ( this.searchFields['id'] ) fields.push('id');
            if ( this.searchFields['email'] ) fields.push('email');
            if ( this.searchFields['lname'] ) fields.push('last_name');
            if ( this.searchFields['fname'] ) fields.push('first_name');
            if ( this.searchFields['uname'] ) fields.push('username');

            return {
                'model': 'Customer',
                'fields': fields,
            }
        },
    },
    props: [],
    methods: {
        DisplayAgencies(customer) {
            let html = ''
            for(let i in customer.accounts) {
                if(i != 0) html += ', '
                html += `<a class="text-indigo-600 hover:text-indigo-900" target="_blank" href="/agencies/${customer.accounts[i].id}" >${customer.accounts[i].name}</a>`
            }
            return html
        },
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
